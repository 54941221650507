@import '../pdGlobal/PdGlobal';

.image-with-text-container {
    position: relative;

    @include media-breakpoint-up(md) {
        min-height: rem(300); // fallback height value until image loads

        &.m-wide-banner {
            min-height: auto;
        }
    }

    .image-component {
        .text-container-placement {
            background-color: $white;
            padding: rem(24) rem(41);
            float: none;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                background-color: transparent;
                margin: 0 rem(32);
                max-width: 44%;
                padding: 0;
            }

            @include media-breakpoint-up(lg) {
                margin: 0 rem(80);
                max-width: 29.2%;
            }

            @include media-breakpoint-up(xxl) {
                margin: rem(56) rem(160);
            }

            .cta-text {
                text-align: center;
                padding-top: rem(24);
                display: flex;
                flex-direction: column;

                @include media-breakpoint-up(lg) {
                    padding-top: rem(48);
                }
            }

            .heading-text {
                margin-top: 0;

                .heading,
                .sub-heading,
                .description {
                    width: auto;
                }

                .heading-text-image {
                    height: auto;
                    max-width: 100%;

                    @include media-breakpoint-up(md) {
                        max-height: rem(120);
                    }

                    @include media-breakpoint-up(lg) {
                        max-height: none;
                    }
                }

                .description {
                    margin-top: rem(24);

                    @include media-breakpoint-up(lg) {
                        margin-top: rem(48);
                    }
                }
            }

            &.left {
                align-items: flex-start;

                .heading-text {
                    text-align: left;

                    .heading {
                        text-align: left;
                    }

                    .description {
                        text-align: left;
                    }
                }

                .cta-text {
                    justify-content: flex-start;
                }
            }

            &.center {
                align-items: center;
                justify-content: center;

                .heading-text {
                    text-align: center;

                    .heading {
                        text-align: center;
                    }

                    .description {
                        text-align: center;
                    }
                }

                .cta-text {
                    align-items: center;
                }
            }

            &.right {
                align-items: flex-end;

                .heading-text {
                    text-align: right;

                    .heading {
                        text-align: right;
                    }

                    .description {
                        text-align: right;
                    }
                }

                .cta-text {
                    justify-content: flex-end;
                }
            }

            @include media-breakpoint-up(md) {
                position: absolute;

                &.bottom-left {
                    bottom: 0;
                    left: 0;
                    margin-bottom: rem(56);
                }

                &.bottom-center {
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-bottom: rem(56);
                }

                &.bottom-right {
                    bottom: 0;
                    right: 0;
                    margin-bottom: rem(56);
                }

                &.center-left {
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.center-center {
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                    margin: 0;
                }

                &.center-right {
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.top-left {
                    top: 0;
                    left: 0;
                    margin-top: rem(56);
                }

                &.top-center {
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-top: rem(56);
                }

                &.top-right {
                    top: 0;
                    right: 0;
                    margin-top: rem(56);
                }

                &.top-center,
                &.bottom-center {
                    margin-left: 0;
                    margin-right: 0;
                }

                &.left {
                    justify-content: center;

                    .cta-text {
                        align-items: center;
                    }
                }

                &.center {
                    justify-content: center;

                    .heading-text {
                        text-align: center;

                        .heading {
                            text-align: center;
                        }

                        .description {
                            text-align: center;
                        }
                    }

                    .cta-text {
                        align-items: center;
                    }
                }

                &.right {
                    justify-content: center;

                    .heading-text {
                        text-align: center;

                        .heading {
                            text-align: center;
                        }

                        .description {
                            text-align: center;
                        }
                    }

                    .cta-text {
                        align-items: center;
                    }
                }

                &.text-desktop-left {
                    align-items: flex-start;

                    .heading-text {
                        text-align: left;

                        .heading {
                            text-align: left;
                        }

                        .description {
                            text-align: left;
                        }
                    }
                }

                &.text-desktop-center {
                    align-items: center;

                    .heading-text {
                        text-align: center;

                        .heading {
                            text-align: center;
                        }

                        .description {
                            text-align: center;
                        }
                    }
                }

                &.text-desktop-right {
                    align-items: flex-end;

                    .heading-text {
                        text-align: right;

                        .heading {
                            text-align: right;
                        }

                        .description {
                            text-align: right;
                        }
                    }
                }
            }
        }

        &.m-mobile-top .text-container-placement {
            @include media-breakpoint-down(sm) {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                margin-top: rem(30);
                background-color: transparent;
            }
        }
    }

    .sub-heading {
        margin-bottom: 0;
    }

    .second-cta-text {
        margin-top: rem(24);
    }

    .first-cta-text,
    .second-cta-text {
        padding: rem(12) rem(16);
        text-transform: uppercase;
        width: rem(220);

        @include media-breakpoint-up(md) {
            width: auto;
        }

        &.underline-cta {
            padding: 0;
            width: auto;
        }
    }

    &.m-wide-banner {
        .image-component .text-container-placement {
            background-color: inherit;
            margin: 2%;
            max-width: none;
            width: 100%;

            &.center-center {
                margin: 0;
            }

            &.center-left,
            &.center-right {
                margin-top: 0;
                margin-bottom: 0;
            }

            &.top-center,
            &.bottom-center {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .m-position-left & {
            order: -1;
        }

        .m-position-right & {
            order: 1;
        }
    }

    &.m-wide-banner-full {
        .image-component .text-container-placement {
            width: max-content;

            @include media-breakpoint-down(sm) {
                left: 50%;
                top: 50%;
                padding: 0;
                position: absolute;
                transform: translate(-50%, -50%);
            }

            .heading-text-image {
                max-height: rem(120);
                height: 8vw;

                @include media-breakpoint-down(sm) {
                    max-height: rem(70);
                    height: auto;
                }
            }
        }
    }

    &.m-wide-banner-next {
        .image-component .text-container-placement {
            @include media-breakpoint-up(md) {
                width: 80%;
                max-width: 80%;
            }

            @include media-breakpoint-down(sm) {
                height: 100%;
                justify-content: center;
                padding: rem(20);
            }

            .heading-text-image {
                max-width: 70%;
            }

            .first-cta-text:not(.underline-cta) {
                @include media-breakpoint-down(sm) {
                    width: auto;
                    padding: rem(5);
                }
            }
        }
    }
}

.wide-banner-adjacent {
    .col-6 {
        flex: 0 0 calc(50% - rem(6));
        max-width: calc(50% - rem(6));

        @include media-breakpoint-up(md) {
            flex: 0 0 calc(33.333% - rem(6));
            max-width: calc(33.333% - rem(6));
        }
    }
}

.two-image-with-text-container {
    width: 100%;
    clear: both;

    .flex-row-container {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        .left-image-container,
        .right-image-container {
            .common-image-component {
                object-fit: cover;
            }
        }

        .right-image-container {
            padding-left: rem(18);
            padding-right: rem(18);
            padding-bottom: rem(24);
            padding-top: 0;

            @include media-breakpoint-up(sm) {
                padding: 0;
            }
        }

        .text-container-placement {
            padding: rem(24) rem(41);

            @include media-breakpoint-up(md) {
                padding: 0;
                width: 100%;
            }

            .heading-text {

                .heading,
                .sub-heading,
                .description {
                    padding: 0 rem(18);
                    text-align: center;
                }

                .heading {
                    padding-left: rem(18);
                    padding-right: rem(18);
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-bottom: rem(24);

                    @include media-breakpoint-up(sm) {
                        margin-bottom: rem(48);
                        padding: 0;
                        margin-top: 0;
                    }
                }

                .description {
                    margin-bottom: rem(24);
                    padding-top: 0;
                    padding-bottom: 0;
                    text-align: center;

                    @include media-breakpoint-up(sm) {
                        margin-bottom: rem(48);
                    }
                    
                    @include media-breakpoint-up(lg) {
                        padding: 0 rem(62);
                    }
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }

            .cta-text {
                display: flex;
                flex-direction: column;

                a {
                    margin: 0 auto;
                }

                .first-cta-text,
                .second-cta-text {
                    text-align: center;
                    text-transform: uppercase;
                    padding-left: rem(18);
                    padding-right: rem(18);
                }

                .second-cta-text {
                    padding-top: rem(10);

                    @include media-breakpoint-up(sm) {
                        padding-top: rem(16);
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                &.left {
                    align-items: flex-start;

                    .description {
                        text-align: left;
                    }

                    .cta-text {
                        align-items: flex-start;
                    }
                }

                &.center {
                    align-items: center;

                    .description {
                        text-align: center;
                    }

                    .cta-text {
                        align-items: center;
                    }
                }

                &.right {
                    align-items: flex-end;

                    .description {
                        text-align: right;
                    }

                    .cta-text {
                        align-items: flex-end;
                    }
                }
            }
        }
    }
}